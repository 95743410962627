import axios from 'axios'


const baseDomain = 'https://api.grunchem.eu/';
const baseURL = `${baseDomain}`;

export default axios.create({
    baseURL,
    headers: {
        Accept: 'application/json'
    }
})

