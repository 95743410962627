import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: localStorage.getItem("locale"),
    loading: false,
    number: 0,
  },
  mutations: {
    SET_LOCALE: (state, payload) => {
      state.locale = payload
    },

    SET_NUMBER: (state) => {
      if (state.number < 5) {
        state.number++
      } else {
        state.number = 0
      }
    },

    SET_NUMBER_PREV: (state) => {
      if (state.number > 0) {
        state.number--
      } else {
        state.number = 5
      }
    },

    SET_LOADING: (state, payload) => {
      setTimeout(() => {
        state.loading = true
      }, payload);
      state.loading = false
    },

  },
  actions: {
    SET_LANGUAGE({
      commit
    }, payload) {
      commit('SET_LOCALE', payload);
    },

    SET_LOADING_AXIOS({
      commit
    }, payload) {
      commit('SET_LOADING', payload);
    },

    SET_NUMBER({commit}) {
      commit('SET_NUMBER');
    },

    SET_NUMBER_PREV({commit}) {
      commit('SET_NUMBER_PREV');
    },
  },
  getters: {
    locale(state) {
      return state.locale
    },
    loading(state) {
      return state.loading
    },
  },
})