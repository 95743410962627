import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'


Vue.use(VueRouter);

//const available_langs = ['bgr', 'en', 'ru'];
  const available_langs = ['en' ,'de', 'fr'];

let lang = '';


window.location.pathname.split('/').forEach(function(item){
  item = item.replace('/', '');

  if(available_langs.includes(item)){
    lang = item
  }
});

if (!window.localStorage.getItem('locale') || window.localStorage.getItem('locale') !== lang) {
  if(lang === ''){
    window.localStorage.setItem('locale', 'de');
    window.location.replace('/' + window.localStorage.getItem('locale'));
  } else {
    window.localStorage.setItem('locale', lang);
    window.location.reload();
  } 
}

const routes = [{
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      layout: 'defaultLayout',
    },
  },
  {
    path: '/poly-methylamine',
    alias: ['/poly-methylamine', '/poly-methylamine'],
    name: 'PolyMethylamine',
    component: () => import('../views/PolyMethylamine.vue'),
    meta: {
      layout: 'defaultLayout'
    }
  },
  {
    path: '/products',
    alias: ['/produkte', '/des-produits'],
    name: 'Products',
    component: () => import('../views/Products.vue'),
    meta: {
      layout: 'defaultLayout'
    },
  },
  {
    path: '/products/:slug',
    name: 'ProductsDetail',
    alias: ['/products/:slug', '/produkte/:slug', '/des-produits/:slug'],
    component: () => import('../views/ProductDeatail.vue'),
    meta: {
      layout: 'defaultLayout'
    },
  },
  {
    path: '/about-us',
    alias: ['/about-us', '/uber-uns', '/a-propos-de-nous'],
    name: 'About Us',
    component: () => import('../views/About.vue'),
    meta: {
      layout: 'defaultLayout'
    }
  },
  {
    path: '/contacts',
    alias: ['/contacts', '/ansprechpartner', '/contacts'],
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      layout: 'defaultLayout'
    }
  },
  {
    path: '/404',
    name: '404',
    meta: {
      layout: 'login',
    },
    component: () => import('../views/404.vue'),
  },
];



const router = new VueRouter({
  mode: 'history',
  base: store.state.locale,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

export default router