<template>

    <div class="wrapper_nav">

        <div class="outer_nav">
            <div
                    class="logo" @click="goHome">
                <img
                        v-if="vocabulary.logo"
                        :src="'https://api.grunchem.eu/storage/' + vocabulary.logo" alt="logo">
            </div>

            <div class="inner_nav">

                <ul :class="(this.width < 992) && this.isActive ? 'mobile' : 'desktop'">
                    <li @click="isActive = !isActive" v-for="(link, i) in data.menu" :key="link[i]">
                        <a :href="'/' + locale + link.slug">
                            {{link.title}}
                        </a>
                    </li>

                    <selectLanguage v-if="(this.width < 992) && this.isActive" :class="{ languageMobile: (this.width < 992) && this.isActive}"/>

                </ul>

                <div class="wrapper_info">
                    <div class="language">

                        <selectLanguage />

                    </div>

                    <div class="phone">
                        <img src="/img/nav/phone.svg" alt="">
                        <a :href="'tel:' + vocabulary.phone">
                            <p>{{vocabulary.phone}}</p>
                        </a>

                    </div>
                </div>

                <input type="checkbox" id="checkbox2" class="checkbox2 visuallyHidden">
                <label for="checkbox2">
                    <div @click="isActive = !isActive" class="hamburger" :class="{hamburger2: isActive}">
                        <span class="bar bar1"></span>
                        <span class="bar bar2"></span>
                        <span class="bar bar3"></span>
                        <span class="bar bar4"></span>
                    </div>
                </label>

            </div>

        </div>
    </div>


</template>

<script>
    import selectLanguage from "./selectLanguage";

    import {
        RepositoryFactory
    } from "../../api/RepositoryFactory";
    const GetRepository = RepositoryFactory.get('data');

    export default {
        name: "navbar",
        components: {
            selectLanguage
        },
        data() {
            return {
                isActive: false,
                width: window.innerWidth,
                data: {},
                vocabulary: '',
                languages: {},
                language: {
                    en: 'en',
                    bgr: 'bgr',
                    ru: 'ru'
                },
                locale: window.localStorage.getItem('locale'),
                languageSelect: 'en',
            }
        },
        methods: {
            fetch() {
                return new Promise((resolve, reject) => {
                    GetRepository.get()
                        .then(resp => {
                            this.vocabulary = resp.data.data.header_footer.vocabulary;
                            this.data = resp.data.data.header_footer;
                            this.languages = resp.data.data.data_locale;
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            },


            updateWidth() {
                this.width = window.innerWidth;
            },

            goHome() {
                document.location.href = "/" + this.locale + '/'
            },

            scrollMenuBg() {

                let nav = document.querySelector('.wrapper_nav');

                if (window.pageYOffset > 50) {
                    nav.classList.add('scroll')

                } else {
                    nav.classList.remove('scroll');
                }
            },
        },
        async mounted() {
            await this.fetch();

            window.addEventListener('resize', this.updateWidth);
            window.addEventListener('scroll', this.scrollMenuBg);

            if (document.location.href === (document.location.origin + document.location.pathname)) {
                let link = document.querySelectorAll('.inner_nav')[0].children[0].children;

                for (let i = 0; i < link.length; i++) {
                    if (document.location.pathname === link[i].children[0].getAttribute('href')) {
                        link[i].children[0].classList.add('article');
                    }
                }

                if (this.$route.name === 'Home') {
                    link[0].children[0].classList.add('article')
                }

            }

            if (window.pageYOffset > 900) {
                this.scrollMenuBg()
            }
        },


    }
</script>

<style scoped lang="scss">
    @import '../../assets/scss/index';

    .wrapper_nav {
        position: fixed;
        background-color: transparent;
        width: 100%;
        height: auto;
        margin: 0 auto;
        right: 0;
        left: 0;
        z-index: 1000;

        & .article {
            &:after {
                display: block !important;
                position: absolute !important;
                left: 0 !important;
                width: 100% !important;
                height: 2px !important;
                background-color: $colorLinkActive !important;
                content: "" !important;
                transition: width 0.5s ease-out !important;
            }
        }

        .languageMobile {
            background-color: rgba(26, 26, 26, 0.68);
            padding: 20px 40px;
            z-index: 10;
        }

        & .outer_nav {
            max-width: 1500px;
            padding: 30px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            transition: $transitionLink;

            @media (max-width: 768px) {
                padding: 15px 20px;
            }

            & .logo {
                cursor: pointer;
                opacity: 0.9;
                z-index: 10000;

                & img {
                    width: 300px;
                    height: 60px;
                    object-fit: contain;

                    @media (max-width: 768px) {
                        width: 200px;
                        height: 40px;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                :hover {
                    transition: $transitionLink;
                    opacity: 1;
                }
            }

            & .inner_nav {
                display: flex;
                justify-content: space-around;
                align-items: center;

                & .desktop {
                    display: flex;
                    justify-content: space-between;
                    transition: 0.4s ease-in;

                    @media (max-width: 992px) {
                        display: none;
                    }

                    & a {
                        @include routerLink
                    }

                    & .router-link-exact-active {
                        color: $colorLinkActive;
                        @include linkActive
                    }
                }

                & .mobile {
                    padding: 100px 20px 0;
                    position: absolute;
                    top: -20px;
                    right: 0;
                    bottom: 0;
                    width: 35vw;
                    height: 100vh;
                    background-color: $spanSocialFooter;
                    z-index: 1000;
                    overflow: hidden;
                    opacity: 0.98;
                    transition: $transitionLink;

                    @media (max-width: 575px) {
                        width: 100vw;
                        padding: 100px 0;
                    }

                    animation-name: slideLeft;
                    -webkit-animation-name: slideLeft;

                    animation-duration: 0.5s;
                    -webkit-animation-duration: 0.5s;

                    animation-timing-function: ease-in-out;
                    -webkit-animation-timing-function: ease-in-out;

                    visibility: visible !important;


                    @keyframes slideLeft {
                        0% {
                            transform: translateX(100%);
                        }

                        100% {
                            transform: translateX(0%);
                        }
                    }

                    & a {
                        @include routerLink;
                        text-align: left;
                        margin: 20px 0 !important;
                        font-size: 20px !important;

                        @media (max-width: 575px) {
                            margin: 30px 40px !important;
                        }
                    }
                }

                & .wrapper_info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: 768px) {
                        display: none;
                    }

                    & .language {
                        margin: 0 50px 0 35px;
                        font-size: 14px;

                        @media (max-width: 1200px) {
                            margin: 0 30px 0 15px;
                        }
                    }

                    & .phone {
                        display: flex;
                        align-items: center;

                        & img {
                            margin-right: 10px;

                            @media (max-width: 1200px) {
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                            }
                        }

                        & a {
                            text-decoration: none;

                            & p {
                                color: $routerLink;
                                font-size: 14px;

                                @media (max-width: 1200px) {
                                    font-size: $fontSizeTables;
                                }
                            }

                            &:hover p {
                                transition: $transitionLink;
                                color: $colorLinkActive;
                            }



                        }



                    }
                }

                & .hamburger {
                    z-index: 1000;
                    display: none;
                    width: 30px;
                    height: 25px;
                    position: relative;
                    margin-left: 50px;

                    @media (max-width: 992px) {
                        display: block;
                    }

                    & .bar {
                        padding: 0;
                        width: 30px;
                        height: 3px;
                        background-color: $colorLinkActive;
                        display: block;
                        border-radius: 4px;
                        transition: all 0.4s ease-in-out;
                        position: absolute;
                    }

                    & .bar1 {
                        top: 0;
                    }

                    & .bar2,
                    & .bar3 {
                        top: 11.5px;
                    }

                    & .bar3 {
                        right: 0;
                    }

                    & .bar4 {
                        bottom: 0;
                    }
                }


                .checkbox2:checked+label>.hamburger2>.bar1 {
                    transform: translateX(40px);
                    background-color: transparent;
                }

                .checkbox2:checked+label>.hamburger2>.bar2 {
                    transform: rotate(45deg);
                }

                .checkbox2:checked+label>.hamburger2>.bar3 {
                    transform: rotate(-45deg);
                }

                .checkbox2:checked+label>.hamburger2>.bar4 {
                    transform: translateX(-40px);
                    background-color: transparent;
                }

                & .checkbox2 {
                    display: none;
                }


            }
        }
    }

    .scroll {
        transition: $transitionLink;
        background-color: $base-color;
    }
</style>