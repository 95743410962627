<template>

  <div id="app" data-server-rendered="true">
<!--    <transition name="page">-->
    <component :is="this.layout">
    <router-view/>
    </component>
<!--    </transition>-->
  </div>

</template>

<script>
import defaultLayout from './layouts/defaultLayout'

  export default {
    components: {
      defaultLayout
    },
    computed: {
      layout () {
        return (this.$route.meta.layout)
      }
    },
      mounted() {

      }
  }

</script>

<style lang="scss">
  @import 'assets/scss/index';

</style>
