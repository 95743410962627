import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios  from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'

import AOS from 'aos'
import 'aos/dist/aos.css'
import 'aos/dist/aos.js'

Vue.use(VueMeta)
Vue.use(VueAxios, axios)

AOS.init({
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
});

import 'swiper/swiper-bundle.css'


import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

Vue.use( VueLazyload, {
  preLoad: 1.3,
  loading: require(`${'../public/img/image-placeholder.gif'}`),
  attempt: 1,
  lazyComponent: true,
  observer: true,
  throttleWait: 500,
});
// Vue.use(Swiper, /* { default options with global component } */)


Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  components: { App },
  data: {
    ln: window.localStorage.getItem('locale')
  },
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');